import clsx from "clsx"
import { FC } from "react"
import Icon from "../../../../Fondations/Icons/Icon"
import styles from "../../Dropdown.module.css"
import { type DropdownMenuContent } from "../Menu"

type DropdownMenuProps = {
  onCloseMenu: () => void
  content: DropdownMenuContent
}

export const MenuButton: FC<DropdownMenuProps> = ({ onCloseMenu, content }) => {
  return (
    <button
      key={content.displayNameKey}
      className={clsx(
        styles.item,
        "gap-3 text-neutral-gray-900 hover:bg-neutral-gray-50",
        content.isDisabled && styles.disabled,
        content.isDanger && `group ${styles.danger}`
      )}
      disabled={content.isDisabled}
      onClick={() => {
        content?.onClick()
        onCloseMenu()
      }}
      type="button"
    >
      {content.iconName && (
        <div
          className={clsx(
            "text-neutral-gray-500 group-hover:text-primary-error-500",
            content.isDisabled && styles.disabled
          )}
        >
          <Icon name={content.iconName} />
        </div>
      )}
      <p className="body-sm-regular whitespace-nowrap truncate">{content.displayNameKey}</p>
    </button>
  )
}
