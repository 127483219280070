import { UserConfigKey, useUserConfig } from "@kamae-apps/shared/Hooks/userConfig/useUserConfig"
import { ToastContainerContext } from "@kamae-apps/shared/old/Component/Toast/Context"
import { ToastContainer } from "@kamae-apps/shared/old/Component/Toast/Toast"
import { TFunction } from "i18next"
import { FC, MutableRefObject, ReactNode, useEffect, useState } from "react"
import { Menu } from "../Organisms/Menu"
import { WelcomeModal } from "../WelcomeModal/WelcomeModal"
import { PageLayout } from "./PageLayout"

type AppLayoutProps = {
  t: TFunction
  loaded: boolean
  toastRef: MutableRefObject<ToastContainer>
  children: ReactNode
}

export const AppLayout: FC<AppLayoutProps> = ({ t, loaded, toastRef, children }) => {
  const { configs } = useUserConfig()
  const [displayWelcomeModal, setDisplayWelcomeModal] = useState(false)

  useEffect(() => {
    if (configs) {
      setDisplayWelcomeModal(configs?.get(UserConfigKey.FIRST_CONNECTION) !== "true")
    }
  }, [configs])

  return (
    <div className={"flex min-h-screen"}>
      <Menu
        t={t}
        loaded={loaded}
      />
      {displayWelcomeModal && (
        <WelcomeModal
          t={t}
          close={() => setDisplayWelcomeModal(false)}
        />
      )}
      <ToastContainerContext.Provider value={toastRef}>
        <div className={"grow overflow-y-auto overflow-x-hidden scroll-smooth custom-scrollbar"}>
          <PageLayout>
            {loaded && children}
            <ToastContainer ref={toastRef} />
          </PageLayout>
        </div>
      </ToastContainerContext.Provider>
    </div>
  )
}
