import { FC, ReactNode } from "react"

type PageLayoutProps = {
  children: ReactNode
}

export const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  return (
    <main className={"h-full ml-60 py-3 pr-3"}>
      <div
        className={
          "h-full flex flex-col glass-effect-xl bg-neutral-white/50 rounded-xl px-6 pt-6 text-neutral-gray-900"
        }
      >
        {children}
      </div>
    </main>
  )
}
