import type TUser from "@kamae-apps/shared/Types/TUser"
import UserService from "../../Services/UserServices"

export async function fetchUserData(): Promise<TUser> {
  return UserService.fetchUserData()
}

export async function updateUserData(body: TUser): Promise<TUser> {
  return UserService.updateUserData(body)
}
