import { useAuth0 } from "@auth0/auth0-react"
import { useCryptr } from "@cryptr/cryptr-react"
import useApiStatus from "@kamae-apps/shared/Hooks/useApiStatus/useApiStatus"
import useReload from "@kamae-apps/shared/Hooks/useReload"
import { UserConfigStateProvider } from "@kamae-apps/shared/Hooks/userConfig/Context"
import type { APIError } from "@kamae-apps/shared/Types/API"
import { getBeltColor } from "@kamae-apps/shared/Types/Belt/Belt"
import { DefaultLanguage } from "@kamae-apps/shared/Types/Company/DefaultLanguage"
import type { TCompany } from "@kamae-apps/shared/Types/Company/TCompany"
import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope"
import type TUser from "@kamae-apps/shared/Types/TUser"
import Loading from "@kamae-apps/shared/old/Component/Loading/Loading"
import { ToastContainerContext } from "@kamae-apps/shared/old/Component/Toast/Context"
import { ToastContainer } from "@kamae-apps/shared/old/Component/Toast/Toast"
import { apiRequest, basicCompare, isLargeScreen } from "@kamae-apps/shared/utils"
import posthog from "posthog-js"
import React, { Suspense, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { NewBeltModal } from "./old/Components/App/NewBeltModal"
import { Header } from "./old/Components/Header/Header"
import { SideBar } from "./old/Components/SideBar/SideBar"
import Error401 from "./old/Error/Error401/Error401"
import Error404 from "./old/Error/Error404"
import LicenseExpired from "./old/Error/LicenseExpired/LicenseExpired"
import MaintenancePage from "./old/Error/MaintenancePage/MaintenancePage"
import { useFeatureFlags } from "./old/hooks/posthog/useFeatureFlags"
import useUser from "./old/hooks/useUser"
import { fromCodeToLang, getCompanyPlan, sha1, shouldThePathBeMemoized } from "./utils"

import { faro } from "@grafana/faro-web-sdk"
import { useWindowSize } from "@kamae-apps/shared/Hooks/useWindowSize"
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus"
import { ConditionalRender } from "@kamae-apps/shared/old/Component/ConditionalRender/ConditionalRender"
import { RebrandedApp } from "./RebrandedApp"
import BotPage from "./old/Components/BotPage/BotPage"
import LearningPage from "./old/Components/LearningPage/LearningPage"
import CompanyDoesNotExist from "./old/Error/CompanyDoesNotExist/CompanyDoesNotExist"
import UserDoesNotExist from "./old/Error/UserDoesNotExist/UserDoesNotExist"
import useIntercom from "./old/hooks/useIntercom"
import config from "./variable"

const Home = React.lazy(() => import("./old/Components/Home/Home"))
const Profile = React.lazy(() => import("./old/Components/ProfilePage/ProfilePage"))
const Trainings = React.lazy(() => import("./old/Components/TrainingsPage/TrainingsPage"))
const PhishingAdmin = React.lazy(() => import("./old/Components/Phishing/components/PhishingAdmin"))
const LibraryPage = React.lazy(() => import("./old/Components/LibraryPage/LibraryPage"))
const Supervision = React.lazy(() => import("./old/Components/Supervision/Supervision"))
const TeamSupervisionPage = React.lazy(
  () => import("./old/Components/Pages/TeamSupervision/TeamSupervisionPage/TeamSupervisionPage")
)
const TeamUserListPage = React.lazy(
  () => import("./old/Components/Pages/TeamSupervision/TeamUserListPage/TeamUserListPage")
)
const CompanyUsers = React.lazy(() => import("./old/Components/OrganizationPage/Containers/Users/Users"))
const Teams = React.lazy(() => import("./old/Components/OrganizationPage/Containers/Teams/Teams"))
const Tools = React.lazy(() => import("./old/Components/OrganizationPage/Containers/Tools/Tools"))
const Login = React.lazy(() => import("./old/Components/LoginPage/LoginPage"))
const NewLogin = React.lazy(() => import("./Routes/Login/LoginPage"))
const PhishingUser = React.lazy(() => import("./old/Components/Phishing/components/PhishingUser"))
const CampaignAdminDetail = React.lazy(
  () => import("./old/Components/Phishing/CampaignAdminDetail/CampaignAdminDetail")
)
const CampaignUserDetail = React.lazy(() => import("./old/Components/Phishing/CampaignUserDetail/CampaignUserDetail"))
const Organization = React.lazy(() => import("./old/Components/OrganizationPage/OrganizationPage"))
const Welcome = React.lazy(() => import("./old/Components/WelcomePage/WelcomePage"))
const Training = React.lazy(() => import("./old/Components/TrainingPage/TrainingPage"))
const ChallengePage = React.lazy(() => import("./old/Components/ChallengePage/ChallengePage"))
const PhishingAdminPage = React.lazy(() => import("./old/Components/NewPhishing/PhishingAdminPage/PhishingAdminPage"))
const CreateCampaignPage = React.lazy(
  () => import("./old/Components/NewPhishing/CreateCampaignPage/CreateCampaignPage")
)
const AutomationPage = React.lazy(() => import("./old/Components/NewPhishing/AutomationPage/AutomationPage"))
const CampaignAdminDetailPage = React.lazy(
  () => import("./old/Components/NewPhishing/CampaignAdminDetailPage/CampaignAdminDetailPage")
)

const emptyCompany: TCompany = {
  name: "",
  id: -1,
  level: -1,
  progress: -1,
  creation: new Date(),
  logo: "",
  status: -1,
  automated_phishing_activated: false,
  phishing_enabled: false,
  sso_enabled: false,
  lms_enabled: false,
  defaultLanguageId: DefaultLanguage.FR,
  dir_sync_enabled: false,
}

export default function App() {
  const size = useWindowSize()
  const [jwt, setJwt] = useState<string | null>(null)

  const [loaded, setLoaded] = useState<boolean>(false)
  const { reload: elearningReload, doReload: doElearningReload } = useReload()
  const [displayNewBelt, setDisplayNewBelt] = useState(false)

  const ref = useRef<ToastContainer>(null)
  const { user, setUserState } = useUser()
  const [searchParams] = useSearchParams()

  const featureFlag = useFeatureFlags()

  const cryptr = useCryptr()
  const auth0 = useAuth0()
  const navigate = useNavigate()
  const location = useLocation()

  const apiStatus = useApiStatus()

  const canToggleRebranding = featureFlag.includes("toggle-rebranding")
  const rebrandingDisplay = localStorage.getItem("rebrandingDisplay") === "true"

  useEffect(() => {
    localStorage.setItem("rebrandingDisplay", JSON.stringify(rebrandingDisplay))
  }, [rebrandingDisplay])

  const { t, i18n } = useTranslation("app")

  const persistLanguage = useCallback(
    (lng?: string) => {
      if (!i18n.isInitialized || !lng) {
        return
      }

      apiRequest("/user/lang", {
        method: "POST",
        body: {
          code: lng,
        },
        headers: {
          "X-Targeted-Language": lng,
        },
      })
    },
    [i18n.isInitialized]
  )

  // Need to be in a useEffect, if not, it will re-subscribe to languageChanged event on each render
  useEffect(() => {
    i18n.on("languageChanged", persistLanguage)

    return () => {
      i18n.off("languageChanged", persistLanguage)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [persistLanguage, i18n])

  const { instantiateIntercom, updateIntercomWithUserInformation, shutdownIntercom } = useIntercom()

  useEffect(() => {
    instantiateIntercom()
  }, [instantiateIntercom])

  useEffect(() => {
    if (loaded && typeof user.scope !== "undefined") {
      if (scopeHas(user.scope, Scope.COMPANY_ADMIN)) {
        updateIntercomWithUserInformation(!isLargeScreen(size))
      } else {
        shutdownIntercom()
      }
    }
  }, [shutdownIntercom, updateIntercomWithUserInformation, loaded, user.scope, size])

  const getUser = useCallback(async () => {
    if (!jwt) {
      return
    }

    try {
      const data = await apiRequest<TUser>("/user")

      if (!data) {
        return
      }

      localStorage.setItem("lang", fromCodeToLang(data.languageId))
      const oldBelt = localStorage.getItem("belt")

      if (oldBelt !== null) {
        if (data.level > Number.parseInt(oldBelt)) {
          setDisplayNewBelt(true)
          const level = data.level
          sha1(data.email).then(identifier => {
            posthog.identify(identifier)
            posthog.capture("earned new belt", {
              level: level,
            })
          })
        }
      }

      localStorage.setItem("belt", data.level.toString())

      if (user === null || !basicCompare(data, user)) {
        setUserState(data)
      }
    } catch (err) {
      const errorsToCatch = [401, 403]
      if (errorsToCatch.includes((err as APIError).code)) {
        const redirectTo = () => {
          switch ((err as APIError).code) {
            case 401:
              return rebrandingDisplay ? "/login?error=unauthorized" : "/unauthorized"
            case 403:
              return "/forbidden"
            default:
              return "/"
          }
        }

        if (cryptr.isAuthenticated()) {
          // We need to use window.location.assign in order to refresh Cryptr SDK values
          await cryptr.logOut(() => window.location.assign(config.app + redirectTo()), undefined, false)
        } else if (auth0.isAuthenticated) {
          auth0.logout({ returnTo: window.location.origin + redirectTo() })
        }
        localStorage.clear()
        if (rebrandingDisplay) {
          localStorage.setItem("rebrandingDisplay", "true")
        }
      }
    }
  }, [jwt, user, setUserState, auth0, cryptr, rebrandingDisplay])

  const RequestAccessTokenCryptr = useCallback(async () => {
    const token = cryptr.getCurrentAccessToken()
    setJwt(token ?? "")
    localStorage.setItem("jwt", token ?? "")
    await getUser()
  }, [cryptr, getUser])

  const RequestAccessTokenAuth0 = useCallback(async () => {
    const token = await auth0.getAccessTokenSilently()
    setJwt(token)
    localStorage.setItem("jwt", token)
    await getUser()
  }, [auth0, getUser])

  useEffect(() => {
    if (
      !cryptr.isAuthenticated() &&
      !cryptr.isLoading &&
      !auth0.isLoading &&
      !auth0.isAuthenticated &&
      !["/unauthorized", "/forbidden", "/company-not-found", "/user-not-found", "/bot", "/learning-page"].includes(
        location.pathname
      )
    ) {
      const state = searchParams.get("state")
      const code = searchParams.get("code")
      if (state === null) {
        // Memoized current url for redirection after login
        if (shouldThePathBeMemoized(location.pathname)) {
          localStorage.setItem("redirectTo", location.pathname)
        }
        navigate(`/login${document.location.search}`)
      } else {
        window.location.replace(`/organization?vantaState=${state}&code=${code}`)
      }
    } else if (cryptr.isAuthenticated()) {
      RequestAccessTokenCryptr()
    } else if (auth0.isAuthenticated) {
      RequestAccessTokenAuth0()
    }
  }, [cryptr, auth0, navigate, RequestAccessTokenAuth0, RequestAccessTokenCryptr, searchParams, location.pathname])

  useEffect(() => {
    if (typeof user.scope !== "undefined") {
      // if user fully loaded
      sha1(user.email).then(identifier => {
        const scopeEnum = Scope
        posthog.register({
          // Capturing this numerical value for every event.
          // It should be captured as a person property instead, but for now
          // we have not been able to compute metrics based on a numerical
          // person property in PostHog (besides using HogSQL).
          company_total_users: user.company.total_user
        })
        posthog.identify(identifier, {
          company_name: user.company.name,
          company_plan: getCompanyPlan(user.company.status),
          company_total_users: user.company.total_user,
          team_name: user.team.name,
          team_total_users: user.team.user_count,
          user_role: scopeEnum[user.scope ?? scopeEnum.USER],
        })
        posthog.group("Company", `id:${user.company.id}`, {
          name: user.company.name,
        })
      })
    }
  }, [user])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (loaded && config.env !== "development") {
      faro.api.setUser({
        email: user.email,
        id: user.id.toString(),
        attributes: {
          company_id: user.company.id.toString(),
          company_name: user.company.name,
        },
      })
    }
  }, [loaded, user.email, user.id, user.company.id, user.company.name])
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    // IF user fully loaded AND featureFlag fully loaded AND we are not in a LoginPage popup
    if (
      typeof user.scope !== "undefined" &&
      featureFlag.length > 0 &&
      (window.opener === null || window.name !== "LoginPopup")
    ) {
      if (!user.avatar) {
        navigate("/welcome")
      } else {
        if (location.pathname === "/") {
          // Redirect the user
          const redirection = localStorage.getItem("redirectTo")
          if (redirection !== null) {
            localStorage.removeItem("redirectTo")
            navigate(redirection ?? "")
          } else {
            if (scopeHas(user.scope, Scope.COMPANY_ADMIN)) {
              if (user.company.onboarding_completed) {
                navigate("/supervision")
              } else {
                navigate("/organization")
              }
            } else {
              navigate("/home")
            }
          }
        }
      }
      setLoaded(true)
    }
  }, [
    featureFlag.length,
    navigate,
    featureFlag,
    location.pathname,
    user.avatar,
    user.company.onboarding_completed,
    user.scope,
  ])

  useEffect(() => {
    sha1(user.email).then(identifier => {
      posthog.identify(identifier)
      posthog.capture("$pageview")
    })
    window.scrollTo({ top: 0 })
  }, [user.email])

  useEffect(() => {
    getUser()
  }, [getUser])

  return (location.pathname.includes("/login") && rebrandingDisplay) || (canToggleRebranding && rebrandingDisplay) ? (
    <RebrandedApp
      loaded={loaded}
      toastRef={ref}
    />
  ) : (
    <div data-theme={"legacy"}>
      {((apiStatus.code === 503 || apiStatus.code === -1) && <MaintenancePage />) || (
        <Routes>
          <Route
            path={"/login"}
            element={
              <Suspense fallback={<Loading />}>
                <Login />
              </Suspense>
            }
          />
          {typeof user.avatar === "undefined" && (
            <Route
              path={"/welcome"}
              element={
                <Suspense fallback={<Loading />}>
                  <UserConfigStateProvider loaded={loaded}>
                    <ToastContainerContext.Provider value={ref}>
                      <ToastContainer ref={ref} />
                      <Welcome />
                    </ToastContainerContext.Provider>
                  </UserConfigStateProvider>
                </Suspense>
              }
            />
          )}
          {user.company.lms_enabled && user.is_lms_user && (
            <Route
              path={"/elearning/:elearningId"}
              element={
                <Suspense fallback={<Loading />}>
                  <Training loaded={loaded} />
                </Suspense>
              }
            />
          )}
          <Route
            element={
              <UserConfigStateProvider loaded={loaded}>
                <div className={"flex min-h-screen flex-col gap-4 pb-12 md:h-screen md:min-h-max md:flex-row md:pb-0"}>
                  <NewBeltModal
                    firstName={user?.first_name ?? ""}
                    display={displayNewBelt}
                    setDisplay={setDisplayNewBelt}
                    beltColor={getBeltColor(user?.level ?? 0)}
                  />
                  {/* Deactivate PhishingModal because wrong date displayed for automated campaign */}
                  {/*<PhishingModal*/}
                  {/*  user={user}*/}
                  {/*  loaded={loaded}*/}
                  {/*/>*/}
                  {!loaded && <Loading />}
                  <div className={"w-full md:w-[230px]"}>
                    <div className={"left-0 top-0 flex h-full w-full justify-center md:w-[230px]"}>
                      <SideBar
                        t={t}
                        loaded={loaded}
                      />
                    </div>
                  </div>
                  <ToastContainerContext.Provider value={ref}>
                    <div
                      className={
                        "custom-scrollbar md:w-main flex w-full grow flex-col overflow-y-auto overflow-x-hidden scroll-smooth p-1 md:p-0 md:pr-2"
                      }
                    >
                      <Header
                        t={t}
                        loaded={loaded}
                      />
                      {loaded && <Outlet />}
                      <ToastContainer ref={ref} />
                    </div>
                  </ToastContainerContext.Provider>
                </div>
              </UserConfigStateProvider>
            }
          >
            <Route
              path={"/home"}
              element={
                <Suspense fallback={<Loading />}>
                  <Home
                    loaded={loaded}
                    reload={elearningReload}
                  />
                </Suspense>
              }
            />
            <Route
              path={"/profile"}
              element={
                <Suspense fallback={<Loading />}>
                  <Profile />
                </Suspense>
              }
            />
            {user.company.phishing_enabled && (
              <Route path={"/phishing"}>
                <Route
                  path={""}
                  element={
                    <Suspense fallback={<Loading />}>
                      {scopeHas(user?.scope, Scope.COMPANY_ADMIN) ? (
                        <>
                          <ConditionalRender condition={featureFlag.includes("phishing-automated")}>
                            <PhishingAdminPage />
                          </ConditionalRender>
                          <ConditionalRender condition={!featureFlag.includes("phishing-automated")}>
                            <PhishingAdmin
                              userCompanyId={user?.company.id}
                              mail={user?.email ?? ""}
                            />
                          </ConditionalRender>
                        </>
                      ) : (
                        <PhishingUser />
                      )}
                    </Suspense>
                  }
                />
                {scopeHas(user?.scope, Scope.COMPANY_ADMIN) && (
                  <Route
                    path={"create"}
                    element={
                      <Suspense fallback={<Loading />}>
                        <CreateCampaignPage />
                      </Suspense>
                    }
                  />
                )}
                {scopeHas(user?.scope, Scope.COMPANY_ADMIN) && featureFlag.includes("phishing-automated") && (
                  <Route
                    path={"automation"}
                    element={
                      <Suspense fallback={<Loading />}>
                        <AutomationPage />
                      </Suspense>
                    }
                  />
                )}
                <Route
                  path={":id"}
                  element={
                    <Suspense fallback={<Loading />}>
                      {scopeHas(user?.scope, Scope.COMPANY_ADMIN) ? (
                        <>
                          <ConditionalRender condition={featureFlag.includes("phishing-automated")}>
                            <CampaignAdminDetailPage />
                          </ConditionalRender>
                          <ConditionalRender condition={!featureFlag.includes("phishing-automated")}>
                            <CampaignAdminDetail />
                          </ConditionalRender>
                        </>
                      ) : (
                        <CampaignUserDetail />
                      )}
                    </Suspense>
                  }
                />
              </Route>
            )}
            <Route
              path={"/reflexes"}
              element={
                <Suspense fallback={<Loading />}>
                  <LibraryPage />
                </Suspense>
              }
            />
            {scopeHas(user?.scope, Scope.COMPANY_ADMIN) && (
              <>
                <Route path={"/supervision"}>
                  <Route
                    path={""}
                    element={
                      <Suspense fallback={<Loading />}>
                        <Supervision />
                      </Suspense>
                    }
                  />
                </Route>
                <Route path={"/organization"}>
                  <Route
                    path={""}
                    element={
                      <Suspense fallback={<Loading />}>
                        <Organization />
                      </Suspense>
                    }
                  />
                  <Route
                    path={"users"}
                    element={
                      <Suspense fallback={<Loading />}>
                        <CompanyUsers company={user?.company ?? emptyCompany} />
                      </Suspense>
                    }
                  />
                  <Route
                    path={"teams"}
                    element={
                      <Suspense fallback={<Loading />}>
                        <Teams
                          company={user?.company ?? emptyCompany}
                          getUser={getUser}
                        />
                      </Suspense>
                    }
                  />
                  {featureFlag.includes("phishing-automated") && (
                    <Route
                      path={"tools"}
                      element={
                        <Suspense fallback={<Loading />}>
                          <Tools />
                        </Suspense>
                      }
                    />
                  )}
                </Route>
              </>
            )}
            {featureFlag.includes("team-leaders") && user.id === user.team.leader?.id && (
              <Route path={"/team"}>
                <Route
                  path=""
                  element={
                    <Suspense fallback={<Loading />}>
                      <TeamSupervisionPage />
                    </Suspense>
                  }
                />
                <Route
                  path={"users"}
                  element={<TeamUserListPage team={user.team} />}
                />
              </Route>
            )}
            <Route
              path={"/elearning"}
              element={
                <Suspense fallback={<Loading />}>
                  <Trainings
                    doReload={doElearningReload}
                    reload={elearningReload}
                  />
                </Suspense>
              }
            />
            {(featureFlag.includes("new-challenge") || user.company.status === CompanyStatus.Discovery) && (
              <Route
                path={"/challenge"}
                element={<ChallengePage />}
              />
            )}
            <Route
              path={"*"}
              element={<Error404 />}
            />
          </Route>
          <Route
            path={"/unauthorized"}
            element={<Error401 />}
          />
          <Route
            path={"/forbidden"}
            element={<LicenseExpired />}
          />
          <Route
            path={"/company-not-found"}
            element={<CompanyDoesNotExist />}
          />
          <Route
            path={"/user-not-found"}
            element={<UserDoesNotExist />}
          />
          <Route
            path={"/bot"}
            element={<BotPage />}
          />
          <Route
            path={"/learning-page"}
            element={<LearningPage />}
          />
        </Routes>
      )}{" "}
    </div>
  )
}
