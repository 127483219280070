import clsx from "clsx"
import { FC } from "react"
import styles from "../Dropdown.module.css"

type MenuSectionProps = {
  value: string
}

export const MenuSection: FC<MenuSectionProps> = ({ value }) => {
  return (
    <div className={clsx(styles.item, "border-b border-neutral-gray-300 text-neutral-gray-600")}>
      <p className="body-sm-semibold">{value}</p>
    </div>
  )
}
