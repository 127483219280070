import type { TFunction } from "i18next"
import { FC } from "react"
import { useLocation } from "react-router-dom"
import { useWindowSize } from "../../../Hooks/useWindowSize"
import { MenuEntry } from "./MenuEntry"

type MenuEntryListProps = {
  t: TFunction
  loaded: boolean
  isAdmin: boolean
  isTeamLeader: boolean
}

type MenuEntries = {
  path: string
  iconName: string
  displayNameKey: string
  isAdminRequired: boolean
  isTeamLeaderRequired: boolean
}

export const MenuEntryList: FC<MenuEntryListProps> = ({ t, isAdmin, isTeamLeader }: MenuEntryListProps) => {
  const menuEntries: MenuEntries[] = [
    {
      path: "/organization",
      iconName: "dashboard",
      displayNameKey: "app:sidebar.organization.label",
      isAdminRequired: true,
      isTeamLeaderRequired: false,
    },
    {
      path: "/supervision",
      iconName: "supervision",
      displayNameKey: "app:sidebar.supervision.label",
      isAdminRequired: true,
      isTeamLeaderRequired: false,
    },
    {
      path: "/team",
      iconName: "manGroup",
      displayNameKey: "app:sidebar.team.label",
      isAdminRequired: false,
      isTeamLeaderRequired: true,
    },
    {
      path: "/home",
      iconName: "tatami",
      displayNameKey: "app:sidebar.home.label",
      isAdminRequired: false,
      isTeamLeaderRequired: false,
    },
    {
      path: "/elearning",
      iconName: "kamaeBeltOutlined",
      displayNameKey: "app:sidebar.elearning.label",
      isAdminRequired: false,
      isTeamLeaderRequired: false,
    },
    {
      path: "/phishing",
      iconName: "phishing",
      displayNameKey: "app:sidebar.phishing.label",
      isAdminRequired: false,
      isTeamLeaderRequired: false,
    },
    {
      path: "/reflexes",
      iconName: "book",
      displayNameKey: "app:sidebar.reflexe.label",
      isAdminRequired: false,
      isTeamLeaderRequired: false,
    },
  ]
  const location = useLocation()
  const filteredMenuEntries = menuEntries.filter(
    menuEntry =>
      (menuEntry.isAdminRequired && isAdmin) ||
      (menuEntry.isTeamLeaderRequired && isTeamLeader) ||
      (!menuEntry.isAdminRequired && !menuEntry.isTeamLeaderRequired)
  )

  return (
    <div className="w-full">
      {filteredMenuEntries.map(entry => (
        <MenuEntry
          key={entry.path}
          content={t(entry.displayNameKey)}
          iconName={entry.iconName}
          goTo={entry.path}
          isActive={entry.path === location.pathname}
          isDisabled={false}
        />
      ))}
    </div>
  )
}
