import { DisplayModal } from "@kamae-apps/shared/Components/Molecules/Modal/Display/Display"
import { TFunction } from "i18next"
import type { FC } from "react"
import { useUser } from "../../Contexts/User/UserContext"

type WelcomeModalProps = {
  t: TFunction
  close: () => void
}

export const WelcomeModal: FC<WelcomeModalProps> = ({ t, close }) => {
  const { user } = useUser()

  return (
    <DisplayModal
      display={true}
      title={t("welcomeModal.title", { name: user.first_name })}
      subTitle={t("welcomeModal.text")}
      buttonText={t("welcomeModal.button")}
      close={close}
    >
      <img
        className="h-[329px] w-133 m-auto"
        src="/tatami-kamaesan.png"
        alt="tatami-kamaesan"
      />
    </DisplayModal>
  )
}
