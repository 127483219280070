import Icon from "@kamae-apps/shared/Components/Fondations/Icons/Icon"
import { DropdownMenu, type DropdownMenuEntries } from "@kamae-apps/shared/Components/Molecules/Dropdown/Menu/Menu"
import useClickOutside from "@kamae-apps/shared/Hooks/useClickOutside"
import { getBeltColor } from "@kamae-apps/shared/Types/Belt/Belt"

import type TUser from "@kamae-apps/shared/Types/TUser"
import clsx from "clsx"
import { FC, useEffect, useRef, useState } from "react"
import config from "../../../variable"

type UserMenuProps = {
  user: TUser
  entries: DropdownMenuEntries[]
}
export const UserMenu: FC<UserMenuProps> = (props: UserMenuProps) => {
  const userMenuRef = useRef<HTMLDivElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  useClickOutside(userMenuRef, () => setIsMenuOpen(false))
  const [avatarError, setAvatarError] = useState(false)

  const avatarSource = `${config.api}/avatar/${props.user.avatar}/${getBeltColor(props.user.level)}`

  return (
    <div
      ref={userMenuRef}
      className="relative"
    >
      <button
        className={clsx(
          "h-13 flex w-full items-center gap-3 rounded-xl bg-white py-3 px-5",
          isMenuOpen ? "bg-opacity-100" : "bg-opacity-30 hover:bg-opacity-50"
        )}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        type="button"
      >
        <div className={"h-7 w-7 flex overflow-hidden rounded-full justify-center	items-center"}>
          {avatarError || !avatarSource ? (
            <Icon
              name="man"
              size="m"
            />
          ) : (
            <img
              src={avatarSource}
              alt="Avatar"
              onError={() => setAvatarError(true)}
            />
          )}
        </div>
        <p className="caption-regular">{props.user.first_name}</p>
      </button>
      {isMenuOpen && (
        <div className="absolute bottom-0 left-[204px] z-10">
          <DropdownMenu
            entries={props.entries}
            onCloseMenu={() => setIsMenuOpen(false)}
          />
        </div>
      )}
    </div>
  )
}
