import style from "./Spinner.module.css"

export const Spinner = () => {
  return (
    <div
      data-name={"spinner"}
      className={style.spinner}
    />
  )
}
