import Icon from "@kamae-apps/shared/Components/Fondations/Icons/Icon"
import clsx from "clsx"
import { FC } from "react"
import { useNavigate } from "react-router-dom"

type MenuEntryProps = {
  content: string
  iconName: string
  goTo: string
  isActive: boolean
  isDisabled: boolean
}

export const MenuEntry: FC<MenuEntryProps> = ({ content, iconName, goTo, isDisabled, isActive }) => {
  const navigate = useNavigate()
  return (
    <button
      disabled={isDisabled}
      onClick={() => navigate(goTo)}
      className={clsx(
        "h-13 flex w-full items-center justify-between rounded-xl p-4 transition-all duration-300 ",
        isActive ? "bg-white" : "bg-transparent hover:bg-white hover:bg-opacity-50"
      )}
      type={"button"}
    >
      <span className={clsx("flex items-center gap-3", isActive ? "text-primary-kamae-500" : "text-neutral-gray-600")}>
        <Icon name={iconName} />
        <p className={clsx("text-neutral-gray-900", isActive ? "caption-semibold" : "caption-regular")}>{content}</p>
      </span>
      <span className={clsx("text-neutral-gray-600", isActive ? "opacity-100" : "opacity-0")}>
        <Icon name="chevronRight" />
      </span>
    </button>
  )
}
