import Icon from "@kamae-apps/shared/Components/Fondations/Icons/Icon"
import clsx from "clsx"
import { FC } from "react"
import { DesignPosition } from "../../DesignStates/DesignPosition"
import { Spinner } from "../Spinner/Spinner"
import styles from "./Button.module.css"
import {
  ButtonVariantDisabled,
  ButtonVariantPrimaryDanger,
  ButtonVariantPrimaryDisabled,
  ButtonVariantPrimaryInitial,
  ButtonVariantPrimarySuccess,
  ButtonVariantSecondaryDanger,
  ButtonVariantSecondaryDisabled,
  ButtonVariantSecondaryInitial,
  ButtonVariantSecondaryLight,
  ButtonVariantSecondarySuccess,
} from "./Button.type"

export type ButtonVariant =
  | ButtonVariantPrimaryInitial
  | ButtonVariantPrimaryDanger
  | ButtonVariantPrimarySuccess
  | ButtonVariantPrimaryDisabled
  | ButtonVariantSecondaryInitial
  | ButtonVariantSecondaryDanger
  | ButtonVariantSecondarySuccess
  | ButtonVariantSecondaryLight
  | ButtonVariantSecondaryDisabled

type IconSize = "m" | "s" | "xs"

type ButtonVariantProps = {
  disabledVariant: ButtonVariantDisabled
  iconSize: IconSize
}

const buttonVariantToProps: Record<ButtonVariant, ButtonVariantProps> = Object.freeze({
  "button-primary-initial-small": { disabledVariant: "button-primary-disabled-small", iconSize: "xs" },
  "button-primary-initial-big": { disabledVariant: "button-primary-disabled-big", iconSize: "m" },
  "button-primary-initial-default": { disabledVariant: "button-primary-disabled-default", iconSize: "s" },
  "button-primary-danger-small": { disabledVariant: "button-primary-disabled-small", iconSize: "xs" },
  "button-primary-danger-big": { disabledVariant: "button-primary-disabled-big", iconSize: "m" },
  "button-primary-danger-default": { disabledVariant: "button-primary-disabled-default", iconSize: "s" },
  "button-primary-success-small": { disabledVariant: "button-primary-disabled-small", iconSize: "xs" },
  "button-primary-success-big": { disabledVariant: "button-primary-disabled-big", iconSize: "m" },
  "button-primary-success-default": { disabledVariant: "button-primary-disabled-default", iconSize: "s" },
  "button-primary-disabled-small": { disabledVariant: "button-primary-disabled-small", iconSize: "xs" },
  "button-primary-disabled-big": { disabledVariant: "button-primary-disabled-big", iconSize: "m" },
  "button-primary-disabled-default": { disabledVariant: "button-primary-disabled-default", iconSize: "s" },
  "button-secondary-initial-small": { disabledVariant: "button-secondary-disabled-small", iconSize: "xs" },
  "button-secondary-initial-big": { disabledVariant: "button-secondary-disabled-big", iconSize: "m" },
  "button-secondary-initial-default": { disabledVariant: "button-secondary-disabled-default", iconSize: "s" },
  "button-secondary-danger-small": { disabledVariant: "button-secondary-disabled-small", iconSize: "xs" },
  "button-secondary-danger-big": { disabledVariant: "button-secondary-disabled-big", iconSize: "m" },
  "button-secondary-danger-default": { disabledVariant: "button-secondary-disabled-default", iconSize: "s" },
  "button-secondary-success-small": { disabledVariant: "button-secondary-disabled-small", iconSize: "xs" },
  "button-secondary-success-big": { disabledVariant: "button-secondary-disabled-big", iconSize: "m" },
  "button-secondary-success-default": { disabledVariant: "button-secondary-disabled-default", iconSize: "s" },
  "button-secondary-light-small": { disabledVariant: "button-secondary-disabled-small", iconSize: "xs" },
  "button-secondary-light-big": { disabledVariant: "button-secondary-disabled-big", iconSize: "m" },
  "button-secondary-light-default": { disabledVariant: "button-secondary-disabled-default", iconSize: "s" },
  "button-secondary-disabled-small": { disabledVariant: "button-secondary-disabled-small", iconSize: "xs" },
  "button-secondary-disabled-big": { disabledVariant: "button-secondary-disabled-big", iconSize: "m" },
  "button-secondary-disabled-default": { disabledVariant: "button-secondary-disabled-default", iconSize: "s" },
})

export type ButtonProps = {
  variant: ButtonVariant
  disabled?: boolean
  loading?: boolean
  buttonType?: "button" | "submit" | "reset"
  children: string
  onClick?: () => void
  iconPosition?: DesignPosition
}

export const Button: FC<ButtonProps> = ({
  variant,
  disabled,
  loading,
  buttonType = "button",
  children,
  onClick,
  iconPosition,
}) => {
  const { iconSize, disabledVariant } = buttonVariantToProps[variant]
  const buttonClass = disabled ? disabledVariant : variant

  return (
    <button
      type={buttonType}
      className={styles[buttonClass]}
      onClick={onClick}
      disabled={disabled || loading}
      data-icon-position={iconPosition}
      data-loading={loading}
    >
      <ButtonContent
        loading={loading}
        iconPosition={iconPosition}
        iconSize={iconSize}
      >
        {children}
      </ButtonContent>
    </button>
  )
}

type ButtonContentProps = {
  loading?: boolean
  iconPosition?: DesignPosition
  iconSize: IconSize
  children: string
}

const ButtonContent: FC<ButtonContentProps> = ({ loading, iconPosition, iconSize, children }) => {
  const getIconMarginClass = (position: DesignPosition | undefined, size: IconSize) => {
    if (position === "left") {
      return size !== "m" ? "ml-1" : "ml-2"
    }
    if (position === "right") {
      return size !== "m" ? "mr-1" : "mr-2"
    }
    return ""
  }

  const content = (
    <>
      {iconPosition === "left" && <ButtonPlusIcon size={iconSize} />}
      <span className={getIconMarginClass(iconPosition, iconSize)}>{children}</span>
      {iconPosition === "right" && <ButtonPlusIcon size={iconSize} />}
    </>
  )

  if (loading) {
    return (
      <span>
        <span className={clsx("collapse h-0 flex")}>{content}</span>
        {loading && <Spinner />}
      </span>
    )
  }

  return <span className="flex">{content}</span>
}

const ButtonPlusIcon: FC<{ size: IconSize }> = ({ size }) => {
  return (
    <Icon
      size={size}
      name={"plus"}
    />
  )
}
