import { Button } from "@kamae-apps/shared/Components/Atoms/Button/Button"
import { FC, ReactNode } from "react"
import style from "./Display.module.css"

type DisplayModalProps = {
  display: boolean
  title: string
  subTitle: string
  buttonText: string
  close: () => void
  children: ReactNode
}

export const DisplayModal: FC<DisplayModalProps> = ({ display, title, subTitle, buttonText, close, children }) => {
  return (
    <div
      className={style["modal-container"]}
      data-display={display}
    >
      <div
        className={style["modal-md"]}
        onClick={e => e.stopPropagation()}
      >
        <div className={style["modal-header"]}>
          <div className={style["modal-header-content"]}>
            <div className={style["modal-header-content-title"]}>
              <h3>{title}</h3>
            </div>
            <p className={style["modal-header-content-subtitle"]}>{subTitle}</p>
          </div>
        </div>
        <div className={style["modal-content"]}>{children}</div>
        <div className={style["modal-footer"]}>
          <Button
            variant="button-primary-initial-default"
            onClick={close}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}
