import { FC } from "react"
import { v4 } from "uuid"
import { Divider } from "../Components/Divider"
import { MenuSection } from "../Components/MenuSection"
import { MenuButton } from "./Components/MenuButton"

type DropdownMenuProps = {
  onCloseMenu: () => void
  entries: DropdownMenuEntries[]
}

export type DropdownMenuEntries = {
  content?: DropdownMenuContent
  divider?: boolean
  menuSection?: string
}

export type DropdownMenuContent = {
  iconName: string
  isDanger?: boolean
  onClick: () => void
  isDisabled?: boolean
  displayNameKey: string
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ onCloseMenu, entries }) => {
  return (
    <div className="min-w-60 max-w-[290px]">
      {entries.map(entry => {
        if (entry.divider) return <Divider key={v4()} />

        if (entry.menuSection) {
          return (
            <MenuSection
              key={entry.menuSection}
              value={entry.menuSection}
            />
          )
        }

        if (entry.content) {
          return (
            <MenuButton
              key={entry.content.displayNameKey}
              content={entry.content}
              onCloseMenu={onCloseMenu}
            />
          )
        }
      })}
    </div>
  )
}
