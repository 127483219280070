import useApiStatus from "@kamae-apps/shared/Hooks/useApiStatus/useApiStatus"
import { UserConfigStateProvider } from "@kamae-apps/shared/Hooks/userConfig/Context"
import Loading from "@kamae-apps/shared/old/Component/Loading/Loading"
import { ToastContainer } from "@kamae-apps/shared/old/Component/Toast/Toast"
import React, { FC, MutableRefObject } from "react"
import { Suspense } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, Route, Routes } from "react-router-dom"
import { AppLayout } from "./Components/Layout/AppLayout"
import UserGuard from "./Routes/Guards/UserGuard"
import MaintenancePage from "./old/Error/MaintenancePage/MaintenancePage"

const NewLogin = React.lazy(() => import("./Routes/Login/LoginPage"))
const WelcomePage = React.lazy(() => import("./Routes/Welcome/WelcomePage"))
const AvatarsPage = React.lazy(() => import("./Routes/Avatars/AvatarsPage"))

type AppProps = {
  loaded: boolean
  toastRef: MutableRefObject<ToastContainer>
}

export const RebrandedApp: FC<AppProps> = ({ loaded, toastRef }) => {
  const apiStatus = useApiStatus()
  const { t } = useTranslation("app")

  return (
    <div
      data-theme={"rebranding"}
      className="bg-gradient-ocean overflow-hidden"
    >
      {((apiStatus.code === 503 || apiStatus.code === -1) && <MaintenancePage />) || (
        <Routes>
          <Route
            element={
              <UserGuard loaded={loaded}>
                <UserConfigStateProvider loaded={loaded}>
                  <AppLayout
                    t={t}
                    loaded={loaded}
                    toastRef={toastRef}
                  >
                    <Outlet />
                  </AppLayout>
                </UserConfigStateProvider>
              </UserGuard>
            }
          >
            <Route
              path={"/organization"}
              element={<Suspense fallback={<Loading />}>{/* <Organization /> */}</Suspense>}
            />
            <Route
              path={"/supervision"}
              element={<Suspense fallback={<Loading />}>{/* <Supervision /> */}</Suspense>}
            />
            <Route
              path={"/home"}
              element={<Suspense fallback={<Loading />}>{/* <Home /> */}</Suspense>}
            />
            <Route
              path={"/elearning"}
              element={<Suspense fallback={<Loading />}>{/* <Trainings /> */}</Suspense>}
            />
            <Route
              path={"/phishing"}
              element={<Suspense fallback={<Loading />}>{/* <Phishing /> */}</Suspense>}
            />
            <Route
              path={"/reflexes"}
              element={<Suspense fallback={<Loading />}>{/* <Reflexes /> */}</Suspense>}
            />
            <Route
              path={"/profile"}
              element={<Suspense fallback={<Loading />}>{<AvatarsPage />}</Suspense>}
            />
          </Route>
          <Route>
            <Route
              path={"/login"}
              element={
                <Suspense fallback={<Loading />}>
                  <NewLogin />
                </Suspense>
              }
            />
            <Route
              path="/welcome"
              element={
                <UserGuard loaded={loaded}>
                  <Suspense fallback={<Loading />}>
                    <WelcomePage />
                  </Suspense>
                </UserGuard>
              }
            />
          </Route>
        </Routes>
      )}
    </div>
  )
}
