import Loading from "@kamae-apps/shared/old/Component/Loading/Loading"
import React, { useEffect } from "react"
import { useUser } from "../../Contexts/User/UserContext"

type UserGuardProps = {
  loaded: boolean
  children: React.ReactNode
}

const UserGuard: React.FC<UserGuardProps> = ({ loaded, children }) => {
  const { user, loadUser } = useUser()
  useEffect(() => {
    if (loaded && !user.scope) {
      loadUser()
    }
  }, [loadUser, loaded, user.scope])

  if (!loaded || !user.scope) return <Loading />

  return <>{children}</>
}
export default UserGuard
