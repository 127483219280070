import type TUser from "@kamae-apps/shared/Types/TUser"
import { apiRequest } from "@kamae-apps/shared/utils"

class UserService {
  fetchUserData(): Promise<TUser> {
    try {
      return apiRequest<TUser>("/user")
    } catch (error) {
      throw new Error(error)
    }
  }
  updateUserData(body: TUser): Promise<TUser> {
    try {
      return apiRequest("/user", {
        method: "PUT",
        body,
      })
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default new UserService()
