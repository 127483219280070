import { useCallback } from "react"
import { apiRequest } from "../../utils"
import { useUserConfigSharedState } from "./Context"

export enum UserConfigKey {
  FIRST_CONNECTION = "first_connection",
}

export function useUserConfig() {
  const [userConfig, setUserConfig] = useUserConfigSharedState()
  return {
    configs: userConfig,
    setConfig: useCallback(
      (key: string, value: string) => {
        apiRequest(`/user/config/${key}`, {
          method: "PUT",
          body: { value: value },
        }).then(() => {
          userConfig?.set(key, value)
          setUserConfig(userConfig)
        })
      },
      [setUserConfig, userConfig]
    ),
  }
}
